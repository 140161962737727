* {
  box-sizing: border-box;
}

.App {
 
  text-align: center;
  width: 100%;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow:hidden;
  min-width: 870px;

}

.ViewHeaderContainer {
  background:#33333310;
  padding-bottom: 8px;
}

.AppHeader {
 display: block;
  overflow: hidden;
  height: 55px;

  min-width: 870px;
}

.NavMain {
  display: inline;
}

.HeaderButtonGroup nav{
    margin: auto;
}

.HeaderButtonGroup a{
  color: black;
  text-decoration: none;
  padding: 5px;
  font-size: 18pt;
}

.HeaderButtonGroup .selected{
  color: #768f0d;
  text-decoration: none;
  padding: 5px;
}

.HeaderLogo{
  margin: auto;
}

#imgLogo{
  margin: auto;
  max-height: 50px;
  padding-left: 20px;
  margin-top: 20px;
}


#txtTitle{
  display: inline;
  position: absolute;
  top: 0px;
  left: 15px;
  font-size: 30px
}

#btnLogin {

  position: absolute;
  right: 10px;
  top: 35px;
  height: 18px;
  border:none;
  box-shadow:1px 1px 2px #768f0d;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  outline: none;
  cursor: pointer;

}

#btnLogin Button:focus {
  outline: none;
}

.HeaderButtonGroup {
  width: 100%;
}

.HeaderButtonGroup .component-button {
  margin: auto;
  float: left;
  width: 33.33%; /* three boxes (use 25% for four, and 50% for two, etc) */
  padding: 5px; 
}
.HeaderButtonGroup .component-button button{
  width: 100%;
  color: white;
  padding: 2px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

.StartPageMainContainer {
  display: flow-root;
  background-position-x: center;
  width: 100%;
  height: 100%;
  background-image: url('./media/startBild2.jpg');
  background-repeat: no-repeat;

}

.StartPageButtonGroup{
  float: right;
  vertical-align: middle;
  margin-top: 10%;
  margin-right: 5%;
}

.StartPageButtonGroup button {

  font-size: 2em;
  width: 100%;
 
  background-color:#768f0d;

  text-align: start;
  margin: 3px;
  text-decoration: none;

}
a:-webkit-any-link {
  text-decoration: none
}
.StartPageButtonGroup button{

  text-decoration: none;

}

.ViewPlanContainer{
  
  width: 100%;
  height: 100%;
  margin: auto;
}


#visualizeCanvas{
  width: 100%;
  height: 100%;
  outline: none;
  margin: auto;
  overflow: hidden;
  
}

.ViewContactFormContainer {
  padding: 20px;
  text-align: start;
  width: 100%;
  height: 100%;
  display: table


}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.ViewContactFormContainer h3{
  padding-left: 25px;
  display: table-row

}



.ContactFormInfosContainer {
  padding: 20px;
  display: flex;
  display: table-row
}
.ContactFormInfosContainerDetails{
  padding: 10px;
}


@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
}

#formMain {
  display: grid;
  width: 80%;
  max-width: 400px;
  height: auto;
  margin: 0px;
  
  margin-left: 50px;
  margin-right: 50px;

}

.ContainerPartnerInfo {
  text-align: left;
  padding: 20px;
}



.form-dialog-delete-options {
  display: grid
}

.form-dialog-delete-options button{
  width: 100%;
  text-align: left;
text-transform: uppercase;
align-items: flex-start
  

}

.form-dialog-wide-button {
  width: 100%;
  color: white;
  background-color: #768f0d;
  height: 45px;
  font-size: 20px;
  outline: none;
}
#cancel-button{
  color:black;
  background-color:transparent;
  height: 45px;
  font-size: 20px;
}



.ViewPlanContainerDescription{
height: 40px;
margin-bottom: 10px;
background-color: #9fc01d;
color: aliceblue;
border:none;
box-shadow:1px 1px 2px #768f0d;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
line-height: 40px;
vertical-align: middle;

}

/****   VISUALIZE */
.Fence3DContainer {


  border: none;

}

.ViewVisualizeContainer {
  
  width: 100%;
  margin: auto;
}
 #btnPhotoUpload{
float: right;
padding: 5px;
margin: 15px;

color: white;

}

#btnVisualizeOptions{
  float: left;
  padding: 5px;
  margin: 15px;

  color: white;
  
  }


  .Anleitung{
    text-align: start;
    padding: 20px
  }

#MapContainer  {
  margin-left: 75px;
}

.MapControls {

display:  flex;

justify-content: flex-end

  
}
#paperLocationSearch{
  margin: 10px;
}

.singleInfoText{
  padding: 20px;
}

.distanceLabel {
  background-color: antiquewhite;
}

ListItem::selection {
background-color: #768f0d
}

.combinedFields {
  display: flex
}
.VisualizeFenceCarouselContainer  {
  align-items: flex-end
  
  }

  .VisualizeFenceCarousel{
    zoom: 0.4;
    width: inherit;
    align-items: flex-end
  }

.VisualizeFenceCarouselContainer .carousel .slide img {
width: 70%;
height: auto;
margin-top: 30px;
}

.VisualizeFenceCarouselContainer .carousel .slide {
  width: fit-content;
 
  }

  .VisualizeFenceListContainer{
    height: fit-content;
  }

.OverViewContainer {
  margin-left: 15px;
  padding-top: 80px;
}

.OverViewContainerText{
  padding-top:20px;
  padding-left: 15px;
  
}

.OverViewContainerComponents {
  display: flex;
  width: auto;

}



.OverViewContainerMapDetails {
  flex: 0 0 50%;
  padding: 15px;

}

.OverViewContainerFEnce3D {
  flex: auto;
  padding: 15px;


}

.OverViewContainerFEnce3D iframe{
  width: 500px;
  height: 500px;

}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.2;
}


.ImageDrag {
  position: relative;
  filter: contrast(120%);
 height: 1%;
  width: 240px;
  background: transparent;
}
.ImageDragPros {
  position: absolute;
  margin: auto;
  width: 240px;
  left: -240px;
  background: transparent;
}

.ImageDragFirst {
  height:auto;
  width: 240px;
  background: transparent;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#txtVersion {
  position: absolute;
  right: 20px;
  bottom: 3px;
  font-size: 11px;
}






