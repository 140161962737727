.component-button {
  display: inline-flex;
  width: 100%;
  flex: 1 0 auto;

}

.component-button.wide {
  width: 100%;
}

.component-button button {
  background-color:#768f0d;
  color: white;
  font-weight: normal;
  border: 0;
  font-size: 2.0rem;
  padding: 10;
  opacity: 0.9;
  filter: alpha(opacity=50);
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;

}


.component-button button:hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
  cursor: pointer;
}
