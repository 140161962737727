.ViewChooseContainer {
    display: inline;

    margin: 5px;
  
}

 #IntroText{
display: flex;
    text-align: justify;
    height: 50px;
    line-height: 50px;
    margin-left: 30px;
 

}


.ChooseButtonGroup {
    display: flex;
    width: 100%;
    height: 80px;
    background-color:#768f0d;
}

.ChooseButtonGroup button {

    background-color:#a3c300;
    color: white;
    border: none;
    margin: 0px 0px 5px 0px;
    width: inherit;
    outline: none;
    font-size: 1em;

}

.ChooseButtonGroup button:hover {

    cursor: pointer;
    background-color:#768f0d;
}

.ChooseButtonGroup button:focus {

  
    background-color:#768f0d;
}


.ComponentsContainer {
    display: flex;
   
    position: absolute;
 height: 100%;
 width: 100%;

  
}


.ContainerChooseHeight {

 
  
    flex-basis: 30%;
}

  

.ContainerChooseFence {

    position: relative;

        width: 10%;
        order: 1;
        flex-basis: 60%;
      height: 100%;
  
    
    }





.carousel .slide .legend {
    bottom: 10px!important;
   

}
.ContainerChooseFence.carousel carousel-slider{
  margin: auto!important;
  background-color: #768f0d!important;
  width: fit-content;
height: 400px;
 

}
.carousel .control-dots {
    position: absolute;
    bottom: 0!important;
    margin: 0px 0!important;
    text-align: center!important;
    width: 100%!important;
    background-color: white;
}
.carousel .control-dots .dot{
    -webkit-transition: opacity .25s ease-in;
    transition: opacity .25s ease-in;
    opacity: .3;
    box-shadow: 0px 0px 0px rgba(0,0,0,0)!important;
    background:#768f0d!important;
    border-radius: 50%;
    width: 15px!important;
    height: 15px!important;
    cursor: pointer;
    display: inline-block;
    margin: 0 4px!important;
    outline: none;
}

.carousel.carousel-slider button{
    background-color: #768f0d!important;
    height:100%;
    /*z-index: 50;*/
}
.carousel-status{
    z-index: 2;
    margin: auto;
    padding-right: 35px!important;
    color: #768f0d!important;
    text-shadow: none!important;
    font-size: 16px!important;
}
    .carousel .thumb img {
        width: 50px!important;
        height: 50px !important;
    }
    
    .carousel .slide img {

        max-height: 290px; 
        padding-bottom: 30px;
        max-width: 490px; /* change this to whatever you want */
        /*width: fit-content;
        height: fit-content;*/
     
  
    }

      .CarouselContainer{
        background: transparent;
        
      }

      .carousel .slider .slide{
        background: transparent;
  
        
 
    }
#fenceBackground {
  filter: brightness(110%);
}




      